import gql from "graphql-tag";

export default gql`
  query ($filter: FilterFindOnerewardsInput) {
    rewardOne(filter: $filter) {
      activityName
      startDate
      endDate
      #method
      parkRun
      anyWhere
      roleActivity
      cardColor
      rewardItem
      sizeList
      rewardCharacter
      image
      pointTarget
      _id
    }
  }
`;
