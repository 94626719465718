import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import AnywhereList from './List'

class Anywhere extends Component {
  render () {
    return (
      <Switch>
        <Route 
          exact
          path="/anywhere"
          component={AnywhereList}
        />
      </Switch>
    )
  }
}

export default Anywhere