import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Input, Button, Alert, Select } from "antd";
import moment from "moment";
import { useMutation } from "@apollo/react-hooks";
import userPointUpdateById from "./../../../graphql/mutations/userPointUpdateById";

export default function RewardEditAchieveForm({ achievement, handleCancel }) {
  // const [dataAchieve, setDataAchieve] = useState(...achievement);
  const dataAchieve = { ...achievement };
  const [form] = Form.useForm();
  const { Option } = Select;

  const [updateUserPointFn, updateUserPointObj] =
    useMutation(userPointUpdateById);

  const [addressConfirmed, setAddressConfirmed] = useState(false);

  // useEffect(() => {
  //   setDataAchieve(...achievement);
  // }, [achievement]);

  useEffect(() => {
    if (!dataAchieve.address) {
      setAddressConfirmed(true);
    }

    form.setFieldsValue({
      name: dataAchieve.user.name,
      phone: dataAchieve.user.phone,
      address: dataAchieve.address
        ? dataAchieve.address
        : "รอนักวิ่งยืนยันที่อยู่",
      achieveDate: moment(dataAchieve.achieveDate).format("DD-MM-YYYY"),
      size: dataAchieve.rewardDetail ? dataAchieve.rewardDetail : "",
      trackingNo: dataAchieve.trackingNo ? dataAchieve.trackingNo : "",
    });
  }, [form]); //eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = (values) => {
    const updateUserPoint = {
      _id: dataAchieve._id,
      address: values.address === "" ? null : values.address,
      rewardDetail: values.size === "" ? null : values.size,
      trackingNo: values.trackingNo === "" ? null : values.trackingNo,
      rewardStatus: values.trackingNo === "" ? "เตรียมรางวัล" : "สำเร็จ",
    };

    updateUserPointFn({
      variables: {
        record: updateUserPoint,
      },
    });

    setTimeout(() => {
      handleCancel();
    }, 1000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      {updateUserPointObj.data && (
        <Alert
          message="แก้ไขข้อมูลสำเร็จ"
          description="ข้อมูลแก้ไขสำเร็จ"
          type="success"
          showIcon
        />
      )}

      {updateUserPointObj.error && (
        <Alert
          message="เกิดช้อผิดพลาด"
          description="ไม่สามารถแก้ไขข้อมูลได้ "
          type="error"
          showIcon
        />
      )}

      {updateUserPointObj.loading && (
        <Alert message="กำลังอัพเดทข้อมูล..." type="info" showIcon />
      )}
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label="ชื่อ" name="name">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="เบอร์โทร" name="phone">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="วันที่วิ่งครบ" name="achieveDate">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="ที่อยู่" name="address">
          <Input disabled={addressConfirmed} />
        </Form.Item>
        <Form.Item label="ขนาด" name="size">
          <Select disabled={addressConfirmed}>
            {dataAchieve.reward.sizeList.map((el, i) => (
              <Option key={i} value={el}>
                {el}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="เลขที่พัสดุ" name="trackingNo">
          <Input disabled={addressConfirmed} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            ยืนยัน
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
