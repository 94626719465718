import gql from 'graphql-tag'

export default gql`
query($page: Int, $perPage: Int, $_operators: OperatorsFilterFindManyreportsInput, $types : String){
  reportPagination(page: $page, perPage: $perPage, sort: _ID_ASC, filter: { _operators: $_operators , types : $types }){
    items{
      _id
      bib
      name
      gender
      phone
      email
      timeCount
    }
    count
  }
}
`