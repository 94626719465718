import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Divider, Layout } from "antd";
import EventForm from "./eventForm";
import _get from "lodash/get";
import EventSider from "./eventSider";
import eventQuery from "../../graphql/queries/eventOne";
import userSearch from "../../graphql/queries/userSearch";
import getUserOne from "../../graphql/queries/getUserOne";
import updateRoleUser from "./../../graphql/mutations/updateRoleUser";
import updateEvent from "../../graphql/mutations/updateEvent";

import { Typography, Space, Form, Select, Button, Modal, Alert } from "antd";

import { ArrowDownOutlined } from "@ant-design/icons";

export default function EditOrganizer(props) {
  const { match } = props;
  const { eventId } = match.params;

  const { Title, Text } = Typography;
  const [form] = Form.useForm();

  const [modalIsVisible, setModalIsVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [changeCompleted, setChangeCompleted] = useState(null);

  const [userFn, userRes] = useLazyQuery(getUserOne);
  const [userSearchFn, userSearchRes] = useLazyQuery(userSearch);
  const [userRoleFn, userRoleRes] = useMutation(updateRoleUser);
  const [updateEventFn, updateEventRes] = useMutation(updateEvent);

  const [userList, setUserList] = useState([]);
  const [newOrganizer, setNewOrganizer] = useState(null);
  const [currentOrganizer, setCurrentOrganizer] = useState(null);
  const [eventInfo, setEventInfo] = useState(null);

  const event = useQuery(eventQuery, {
    variables: {
      _id: eventId,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    userSearchFn({
      variables: { userName: "" },
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (event.data) {
      setEventInfo(event.data.eventOne);
      setCurrentOrganizer({
        id: event.data.eventOne.organizId,
        name: event.data.eventOne.organizName,
        email: event.data.eventOne.organizEmail,
        phone: event.data.eventOne.organizPhone,
      });
    }

    if (userSearchRes.data) {
      setUserList(userSearchRes.data.userSearch);
    }

    if (userRes.data) {
      setNewOrganizer(userRes.data.userOne);
    }
  }, [event.data, userSearchRes.data, userRes.data]);

  const handleSearch = (value) => {
    userSearchFn({
      variables: { userName: value },
    });
  };

  const handleChange = (value) => {
    userFn({ variables: { filter: { name: value } } });
  };

  const handleSubmit = (value) => {
    setModalIsVisible(true);
    setLoading(true);
  };

  const handleCancel = () => {
    setModalIsVisible(false);
    setLoading(false);
  };

  const changeRole = async (userId, userName, newRole) => {
    await userRoleFn({
      variables: {
        _id: userId,
        role: newRole,
        updatedAt: new Date(Date.now()),
      },
    });

    if (userRoleRes.data) {
      setChangeCompleted(`roleของคุณ${userName} ถูกเปลี่ยนเป็น ${newRole}`);
      setTimeout(() => {
        setChangeCompleted(null);
      }, 1000);
    }

    if (userRoleRes.error) {
      setModalIsVisible(false);
      setChangeCompleted(null);
      setError("เปลียน role ไม่สำเร็จ กรุณาลองอีกครั้ง");
      return;
    }
  };

  const handleOk = async () => {
    //CHANGE EVENT'S ORGANIZER DETAIL
    setModalIsVisible(false);

    updateEventFn({
      variables: {
        record: {
          _id: eventInfo._id,
          organizId: newOrganizer._id,
          organizEmail: newOrganizer.email,
          organizName: newOrganizer.name,
          organizPhone: newOrganizer.phone,
        },
      },
    });

    if (updateEventRes.error) {
      setModalIsVisible(false);
      setChangeCompleted(null);
      setError("เปลียนข้อมูลผู้ดูแลสนาม ไม่สำเร็จ กรุณาลองอีกครั้ง");
      return;
    }

    //CHANGE ROLE USER
    await changeRole(currentOrganizer.id, currentOrganizer.name, "USER");
    await changeRole(newOrganizer._id, newOrganizer.name, "ORGANIZER");

    setLoading(false);
    form.resetFields();
    setNewOrganizer(null);
  };

  const handleAlert = () => {
    setError(null);
  };

  return (
    <Layout>
      {event && <EventSider />}
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        {error && (
          <Alert
            message="เกิดข้อผิดพลาด"
            description={error}
            type="error"
            showIcon
            closable
            onClose={handleAlert}
          />
        )}
        {loading && <Alert message="Loading" type="info" showIcon />}
        {changeCompleted && (
          <Alert
            message="สำเร็จ"
            description={changeCompleted}
            type="success"
            showIcon
            // onClose={handleAlert}
          />
        )}

        <div
          style={{
            border: "0.5px solid ",
            borderColor: "#999",
            padding: "20px",
          }}
        >
          {/* <Space direction="vertical"> */}
          <Title level={4}>ผู้ดูแลสนามคนปัจจุบัน</Title>
          {/* <Text>fdsoafdnksl;o</Text> */}
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
            <Form.Item
              label="ชื่อผู้ดูแล"
              name="name"
              hasFeedback
              help={
                currentOrganizer &&
                `Email : ${currentOrganizer.email}   ,  Phone : ${currentOrganizer.phone}`
              }
            >
              {currentOrganizer && <Text>{currentOrganizer.name}</Text>}
            </Form.Item>
          </Form>
        </div>

        <Divider>
          <ArrowDownOutlined style={{ fontSize: 32 }} />
        </Divider>

        <div
          style={{
            border: "0.5px solid ",
            borderColor: "#999",
            padding: "20px",
          }}
        >
          <Title level={4}>ผู้ดูแลสนามคนใหม่</Title>

          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="ชื่อผู้ดูแล"
              name="name"
              hasFeedback
              help={
                newOrganizer &&
                `Email : ${newOrganizer.email}   ,  Phone : ${newOrganizer.phone}`
              }
            >
              <Select
                showSearch
                onSearch={(e) => handleSearch(e)}
                onChange={(e) => handleChange(e)}
              >
                {userList &&
                  userList.map((el, i) => (
                    <Select.Option key={i} value={el.name}>
                      {el.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={!newOrganizer}
              >
                ยืนยัน
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={modalIsVisible}
          title="คำเตือน!!!"
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              ยกเลิก
            </Button>,

            <Button key="submit" type="primary" onClick={handleOk}>
              ยืนยัน
            </Button>,
          ]}
        >
          {currentOrganizer && (
            <p>
              คุณ <u>{` ${currentOrganizer.name} `} </u>
              จะถูกเปลี่ยนจากผู้ดูแลสนามเป็นนักวิ่ง และจะไม่สามารถเข้าในระบบ
              Admin ได้อีกต่อไป
            </p>
          )}
          <p>
            กดปุ่ม<u> ยืนยัน </u>เพื่อดำเนินการต่อ
          </p>
        </Modal>
      </Layout.Content>
    </Layout>
  );
}
