import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { Input, Button, Select, Modal } from "antd";
import { Form } from "@ant-design/compatible";
import { SaveOutlined } from "@ant-design/icons";

import compose from "lodash/flowRight";
import _includes from "lodash/includes";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import updateEventMutation from "../../graphql/mutations/updateEvent";
// import updateUserMutation from "../../graphql/mutations/updateUser";
import clientAuth from "../../utils/clientAuth";
import ResizeUploader from "../resizeImageUploader";
// import { resetApolloContext } from "@apollo/client";
import userSearch from "../../graphql/queries/userSearch";
import getUserOne from "../../graphql/queries/getUserOne";
import updateRoleUser from "./../../graphql/mutations/updateRoleUser";
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const EventForm = (props) => {
  const { form, event } = props;
  const role = clientAuth.login().role;
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [approveChange, setApproveChange] = useState(false);
  const [eventUpdateById] = useMutation(updateEventMutation);
  // const [userUpdateById] = useMutation(updateUserMutation);
  const [userRoleFn, userRoleRes] = useMutation(updateRoleUser);
  const [currentRD, setCurrentRD] = useState(null);
  const [newRD, setNewRD] = useState({
    phone: _get(event, "organizPhone"),
    email: _get(event, "organizEmail"),
    // organizId: _get(event, "organizId"),
  });
  const [userList, setUserList] = useState([]);

  const [userFn, userRes] = useLazyQuery(getUserOne);
  const [userSearchFn, userSearchRes] = useLazyQuery(userSearch);

  useEffect(() => {
    setCurrentRD(event.organizId);
    if (userSearchRes.data) {
      setUserList(userSearchRes.data.userSearch);
    }

    if (userRes.data) {
      setNewRD(userRes.data.userOne);
    }
  }, [userSearchRes.data, userRes.data, event]);

  // console.log(newRD);
  // console.log(event);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    form.validateFieldsAndScroll((err, record) => {
      if (err) {
        setLoading(false);
        return console.error(err);
      }
      if (event) {
        // console.log(record);
        eventUpdateById({
          variables: {
            record: {
              _id: event._id,
              ...record,
              organizId: newRD._id,
            },
          },
        })
          .then(async (res) => {
            if (approveChange && record.approved === "approve") {
              // console.log(currentRD);
              // console.log(res.data.eventUpdateById.record.organizId);
              if (res.data.eventUpdateById.record.organizId) {
                await userRoleFn({
                  variables: {
                    _id: res.data.eventUpdateById.record.organizId,
                    role: "ORGANIZER",
                    updatedAt: new Date(Date.now()),
                  },
                }).then((userUpdateRes)=>{
                  
                  // console.log(userUpdateRes);
                  if(userUpdateRes.data.changeRole){
                    Modal.success({
                      title: "Updated",
                      onOk: () => {
                        setLoading(false);
                        props.history.push(`/fetchPage?link=/events/${event._id}/edit`);
                      },
                    });
                  }

                })
              }
            }
            // Modal.success({
            //   title: "Updated",
            //   onOk: () => {
            //     setLoading(false);
            //     props.history.push(`/fetchPage?link=/events/${event._id}/edit`);
            //   },
            // });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    });
  };

  const level = [
    {
      value: "every",
      label: "จัดทุกสัปดาห์",
    },
    {
      value: "twice",
      label: "จัดเดือนละ 2 ครั้ง (จัดวันเสาร์แรกและเสาร์สามของเดือน)",
    },
    {
      value: "onec",
      label: "จัดเดือนละ 1 ครั้ง (จัดวันเสาร์แรก)",
    },
  ];

  const regionList = [
    {
      value: "ภาคเหนือ",
      label: "ภาคเหนือ",
    },
    {
      value: "ภาคตะวันออกเฉียงเหนือ",
      label: "ภาคตะวันออกเฉียงเหนือ",
    },
    {
      value: "ภาคตะวันตก",
      label: "ภาคตะวันตก",
    },
    {
      value: "ภาคกลาง",
      label: "ภาคกลาง",
    },
    {
      value: "ภาคตะวันออก",
      label: "ภาคตะวันออก",
    },
    {
      value: "ภาคใต้",
      label: "ภาคใต้",
    },
  ];

  const handleApproveChange = (e) => {
    if (e === "approve") {
      setApproveChange(true);
    }
  };

  const handleSearch = (value) => {
    userSearchFn({
      variables: { userName: value },
    });
  };

  const handleChange = (value) => {
    // console.log(value);

    userFn({ variables: { filter: { name: value } } });

    // setRdName(value);
  };

  // console.log(rdName);
  return (
    <Form>
      {_get(event, "_id") ? <h2>แก้ไขสนามวิ่ง</h2> : <h2>สร้างงานวิ่ง</h2>}
      <Form.Item {...formItemLayout} label="ชื่องานวิ่ง" hasFeedback>
        {getFieldDecorator("name", {
          initialValue: _get(event, "name"),
          rules: [{ required: true, message: "กรุณากรอกชื่องานวิ่ง" }],
        })(<Input />)}
      </Form.Item>
      {_includes(role, "ADMIN") && (
        <Form.Item {...formItemLayout} label="URL slug" hasFeedback>
          {getFieldDecorator("slug", {
            initialValue: _get(event, "slug"),
            rules: [{ required: true, message: "กรุณากรอก slug" }],
          })(<Input />)}
        </Form.Item>
      )}
      <Form.Item {...formItemLayout} label="พิกัด GPS สนาม" hasFeedback>
        {getFieldDecorator("address", {
          initialValue: _get(event, "address"),
          rules: [{pattern : new RegExp(/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/),message: 'รูปแบบพิกัด GPS ไม่ถูกต้อง'},{required: true, message: "กรุณากรอก พิกัด" }],
        })(<Input />)}
      </Form.Item>
      {/* <Form.Item {...formItemLayout} label="ชื่อผู้ดูแล" hasFeedback>
        {getFieldDecorator("organizName", {
          initialValue: _get(event, "organizName"),
          rules: [{ required: true, message: "กรุณากรอก ชื่อผู้ดูแล" }],
        })(<Input disabled={false} />)}
      </Form.Item> */}
      <Form.Item {...formItemLayout} label="ชื่อผู้ดูแล" hasFeedback>
        {getFieldDecorator("organizName", {
          initialValue: _get(event, "organizName"),
          rules: [{ required: true, message: "กรุณากรอก ชื่อผู้ดูแล" }],
        })(
          <Select
            showSearch
            onSearch={(e) => handleSearch(e)}
            onChange={(e) => handleChange(e)}
            disabled
          >
            {userList &&
              userList.map((el, i) => (
                <Select.Option key={i} value={el.name}>
                  {el.name}
                </Select.Option>
              ))}
          </Select>
          // </Select>
        )}
      </Form.Item>
      <Form.Item {...formItemLayout} label="เบอร์โทรผู้ดูแล" hasFeedback>
        {getFieldDecorator("organizPhone", {
          // initialValue: _get(event, "organizPhone"),
          initialValue: newRD.phone,
          rules: [{ required: true, message: "กรุณากรอก เบอร์โทรผู้ดูแล" }],
        })(<Input disabled={true} />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="อีเมลผู้ดูแล" hasFeedback>
        {getFieldDecorator("organizEmail", {
          // initialValue: _get(event, "organizEmail"),
          initialValue: newRD.email,
          rules: [{ required: true, message: "กรุณากรอก อีเมลผู้ดูแล" }],
        })(<Input disabled={true} />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="แนะนำสนามวิ่ง" hasFeedback>
        {getFieldDecorator("description", {
          initialValue: _get(event, "description"),
          rules: [{ required: true, message: "กรุณากรอก แนะนำสนามวิ่ง" }],
        })(<Input />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="จัดวันวิ่ง" hasFeedback>
        {getFieldDecorator("levels", {
          initialValue: _get(event, "levels"),
        })(
          <Select placeholder="เลือก จัดวันวิ่ง">
            {level &&
              level.map((le, i) => (
                <Select.Option key={i} value={le.value}>
                  {le.label}
                </Select.Option>
              ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item {...formItemLayout} label="ภาค" hasFeedback>
        {getFieldDecorator("region", {
          initialValue: _get(event, "region"),
        })(
          <Select placeholder="เลือก ภาค">
            {regionList &&
              regionList.map((le, i) => (
                <Select.Option key={i} value={le.value}>
                  {le.label}
                </Select.Option>
              ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item {...formItemLayout} label="รายละเอียดแผนที่วิ่ง" hasFeedback>
        {getFieldDecorator("road", {
          initialValue: _get(event, "road"),
          rules: [{ required: true, message: "กรุณากรอก รายละเอียดแผนที่วิ่ง" }],
        })(<TextArea />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="การเดินทางไปจุดนัดพบ" hasFeedback>
        {getFieldDecorator("route", {
          initialValue: _get(event, "route"),
          rules: [{ required: true, message: "กรุณากรอก การเดินทางไปจุดนัดพบ" }],
        })(<TextArea />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="ที่อยู่" hasFeedback>
        {getFieldDecorator("location", {
          initialValue: _get(event, "location"),
          rules: [{ required: true, message: "กรุณากรอก ที่อยู่" }],
        })(<TextArea />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="จุดนัดพบ" hasFeedback>
        {getFieldDecorator("startPoint", {
          initialValue: _get(event, "startPoint"),
          rules: [{ required: true, message: "กรุณากรอก จุดนัดพบ" }],
        })(<Input />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="เส้นชัย" hasFeedback>
        {getFieldDecorator("finishPoint", {
          initialValue: _get(event, "finishPoint"),
          rules: [{ required: true, message: "กรุณากรอก เส้นชัย" }],
        })(<Input />)}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Facebook Link" hasFeedback>
        {getFieldDecorator("facebookURL", {
          initialValue: _get(event, "facebookURL"),
          // rules: [{ required: true, message: "กรุณากรอก เส้นชัย" }],
        })(<Input />)}
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="อัพโหลดรูปหน้าปกสนามวิ่ง ขนาด 300x200 px"
      >
        {getFieldDecorator("image", {
          initialValue: _get(event, "image"),
        })(<ResizeUploader dimension={1198} />)}
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="อัพโหลดรูปเพจสนามวิ่ง ขนาด 1300x400 px"
      >
        {getFieldDecorator("banner", {
          initialValue: _get(event, "banner"),
        })(<ResizeUploader dimension={1198} />)}
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="อัพโหลดรูปแผนที่วิ่งของสนาม ขนาด 750x300 px"
      >
        {getFieldDecorator("eventRoute", {
          initialValue: _get(event, "eventRoute"),
        })(<ResizeUploader dimension={1198} />)}
      </Form.Item>
      {_includes(role, "ADMIN") && (
        <Form.Item {...formItemLayout} label="approved" hasFeedback>
          {getFieldDecorator("approved", {
            initialValue: _get(event, "approved"),
            rules: [{ required: true, message: "approved" }],
          })(
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => handleApproveChange(e)}
            >
              <Select.Option key={"approve"} value={"approve"}>
                approve
              </Select.Option>
              <Select.Option key={"reject"} value={"reject"}>
                reject
              </Select.Option>
              <Select.Option key={"waiting"} value={"waiting"}>
                waiting
              </Select.Option>
            </Select>
          )}
        </Form.Item>
      )}

      <Button
        onClick={handleSubmit}
        type="primary"
        htmlType="submit"
        loading={loading}
        icon={<SaveOutlined />}
      >
        บันทึก
      </Button>
    </Form>
  );
};

export default compose(Form.create())(EventForm);
