import gql from "graphql-tag";

export default gql`
  mutation changeRole($_id: MongoID!, $role: String!, $updatedAt: Date) {
    changeRole(_id: $_id, role: $role, updatedAt: $updatedAt) {
      _id
      name
      role
      updatedAt
    }
  }
`;
