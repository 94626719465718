import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { Input, Spin, Layout, Table, Select, Space, Button, Modal } from 'antd'
import moment from 'moment'
import 'moment/locale/th'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import AllRunnerQuery from '../../graphql/queries/getAllRunnerReport'
import exportAllRunnerAnywhereCountMutation from '../../graphql/mutations/exportAllRunnerAnywhereCount'
import updateAllrunnerAnywhereReportMutation from "../../graphql/mutations/updateAllrunnerAnywhereReport";
import ReportSider from './reportSider'
const Search = Input.Search

const Runner = (props) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 750)
	// const [time, setTime] = useState(25)
	const [date, setDate ] = useState(moment().startOf('day').toDate())
	const [searchKey, setSearchKey] = useState('')
	const [page, setPage] = useState(1)
	const [loadingExport, setLoading] = useState(false)
	const PERPAGE = 100

	const { data, loading, refetch } = useQuery(AllRunnerQuery, {
		variables:{
			page,
			types : "anywhere",
			perPage: PERPAGE,
		},
    fetchPolicy: 'network-only',
	})

	const [exportAllRunnerAnywhereCount] = useMutation(exportAllRunnerAnywhereCountMutation)
	const [updateAllrunnerAnywhereReport] = useMutation(updateAllrunnerAnywhereReportMutation);
	
	if(loading){
    return <Spin />
	}

	const bibNumber = (number) => {
		const pad = '0000000'
		return pad.substr(0, pad.length - `${number}`.length) + number
	}

	// let RunnerReport

	// if (!loading && data.reportMany) {
  //   RunnerReport = data.reportMany.filter(runner => {
  //     const e = !![runner.bib.toString(), runner.name].find(
  //       k => {
  //         return k.toLowerCase().replace(/\s/g, '').search(searchKey) !== -1
  //       }
  //     )
  //     return e
  //   })
  // }

	const countRuner = _get(data, 'reportPagination.count', 0)
	const time = moment().format('YYYY-MM-DD')

	const columns = [{
		title: 'เลขบิบ',
		dataIndex: 'bib',
		key: 'bib',
		render: (bib) => bibNumber(Number(bib))
	},{
		title: 'ชื่อ',
		dataIndex: 'name',
		key: 'name',
	}, {
		title: 'เพศ',
		dataIndex: 'gender',
		key: 'gender',
		render: (gender) => gender === 'male'? 'ชาย': 'หญิง'
	},{
		title: 'อีเมล',
		dataIndex: 'email',
		key: 'email',
	},{
		title: 'เบอร์โทร',
		dataIndex: 'phone',
		key: 'phone',
	},{
		title: 'จำนวนครั้งในการวิ่ง',
		dataIndex: 'timeCount',
		key: 'timeCount',
	}]

	const startDate = moment('2020-12-05')
	const nowDate = moment()
	const count = (nowDate.diff(startDate, 'days')+1)/7
	let datelist = []
	datelist.push(moment(startDate).format('YYYY-MM-DD'))
	for(let i = 1; i < count; i++){
		
		const d = startDate.add(7, 'days').format('YYYY-MM-DD')
		datelist.push(d)
	}

	const onLoadMore = (page) => {
		setPage(page)
		refetch({
			page,
			perPage: PERPAGE,
			types : "anywhere"
		})
	}

	const onSearch = (value) => {
		console.log(value);
		if(value === '' || value === null){
			refetch({
				page,
				perPage: PERPAGE,
				types : "anywhere",
				_operators: {"bib": {"nin": 0 } }
			})
		} else {
			refetch({
			page,
			perPage: PERPAGE,
			types : "anywhere",
			_operators: {"bib": {"in": parseInt(value) } }
		})
		}
		
	}

	const onAllRunnerExportSubmit = (e) => {
		e.preventDefault()
		setLoading(true)
		exportAllRunnerAnywhereCount({
			variables: {
				types : "anywhere"
			}
		}).then(async (res) => {
			Modal.success({
				title: 'Download',
				content: (
					<div>
						<a href={`${res.data.exportAllRunnerAnywhereCount.url}`} download>{res.data.exportAllRunnerAnywhereCount.fileName}</a>
					</div>
				),
				onOk: () => {
					setLoading(false)
					props.history.push(`/fetchPage?link=/report/anywhere`)
				}
			})
		})
		
	}

	const onAllRunnerUpdate = (e) => {
		e.preventDefault();
		setLoading(true);
		updateAllrunnerAnywhereReport({
		  variables: {
			types : "anywhere"
		  }
		}).then(async (res) => {
		  // console.log(res);
		  if (res.data.updateAllrunnerAnywhereReport) {
			Modal.success({
			  title: "update completed",
			  onOk: () => {
				setLoading(false);
				props.history.push(`/fetchPage?link=/report/anywhere`);
			  },
			});
		  } else {
			Modal.error({
			  title: "update error",
			  onOk: () => {
				setLoading(false);
				props.history.push(`/fetchPage?link=/report/anywhere`);
			  },
			});
		  }
		});
	  };


  return(
		<Layout>
			<ReportSider />
			<Layout.Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
				<div>
				<Space>
					<p style={!isMobile ? { margin: '8px' } : null}>ค้นหา</p>
					<Search
						disabled={loading}
						placeholder="เลขบิบ ชื่อ"
						onChange={ e => setSearchKey(e.target.value)}
						onSearch={ onSearch }
						style={!isMobile ? { width: '200px', margin: '8px' } : null}
					/>
					<Button loading={loadingExport} onClick={onAllRunnerUpdate}>Update Data</Button>
					<Button loading={loadingExport} onClick={onAllRunnerExportSubmit}>Export</Button>
					{/* <ExcelFile
						element={<Button>Export</Button>}
						filename={`Runner${time}`}
					>
						<ExcelSheet data={data.reportMany} name="runner">
								<ExcelColumn label="bib" value="bib"/>
								<ExcelColumn label="name" value="name"/>
								<ExcelColumn label="gender" value="gender"/>
								<ExcelColumn label="phone" value="phone"/>
								<ExcelColumn label="email" value="email"/>
								<ExcelColumn label="timeCount" value="timeCount"/>
						</ExcelSheet>
					</ExcelFile> */}
				</Space>
				
				{/* <Button onClick={onSubmit}>ค้นหา</Button> */}
        <Table
          style={{whiteSpace: 'nowrap', background: '#fff'}}
          scroll={{ x: true }}
          columns={columns}
          dataSource={_get(data, 'reportPagination.items', [])}
          loading={loading}
          locale={{emptyText: 'ไม่มีข้อมูล'}}
          rowKey={record => record._id}
          pagination={{ size: 'small', current: page, pageSize: PERPAGE, total: countRuner, onChange: onLoadMore }}
        />
				</div>
			</Layout.Content>
		</Layout>
	)
}

export default Runner