import React from "react";
import { useState, useReducer, useEffect } from "react";
import "./rewardStyle.css";
import {
  Form,
  Input,
  Button,
  Popover,
  InputNumber,
  DatePicker,
  Upload,
  Modal,
  Checkbox,
  Row,
  Col,
  Spin,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import firebase from "firebase/app";

import "firebase/storage";
import { CirclePicker } from "react-color";
// import RewardCard from "./RewardCard";
import RewardCardV2 from "./RewardCard_V2";
import createReward from "./../../../graphql/mutations/createOneReward";
import updateRewardById from "../../../graphql/mutations/updateRewardById";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";

export default function RewardForm({
  mode,
  edit,
  handleCloseModal,
  refetchData,
}) {
  const [createOneReward, { data, loading, error }] = useMutation(createReward);
  const [updateRewardFn, updateRewardOjb] = useMutation(updateRewardById);

  const [errorMessage, setErrorMessage] = useState("");

  const defaultImage =
    "https://firebasestorage.googleapis.com/v0/b/parkrun-th.appspot.com/o/Reward%2FDefault_Reward.png?alt=media&token=40e7eb53-10de-4cfe-8622-ad8dd674e0b3";

  const { TextArea } = Input;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [cardColor, setCardColor] = useState("#f44336");
  const [activityName, setActivityName] = useState(null);
  const [pointTarget, setPointTarget] = useState(null);
  const [form] = Form.useForm();
  const [imageUrl, setImageURL] = useState(defaultImage);
  const [circleColor, setCircleColor] = useState("#f44336");

  useEffect(() => {
    if (mode === "edit" && edit) {
      // const sizelist = edit.sizeLitst;

      form.setFieldsValue({
        activityName: edit.activityName,
        pointTarget: edit.pointTarget,
        // method: edit.method,
        roleActivity: edit.roleActivity,
        cardColor: edit.cardColor,
        rewardItem: edit.rewardItem,
        sizeList: edit.sizeList,
        rewardCharacter: edit.rewardCharacter,
        image: edit.image,
        startDate: moment(new Date(edit.startDate), "DD-MM-YYYY"),
        endDate: edit.endDate && moment(new Date(edit.endDate), "DD-MM-YYYY"),
        method: [
          edit.parkRun ? "parkRun" : "",
          edit.anyWhere ? "anyWhere" : "",
        ],
      });

      setImageURL(edit.image);
      setCardColor(edit.cardColor);
      setActivityName(edit.activityName);
      setPointTarget(edit.pointTarget);
    }

    if (mode === "create") {
      form.setFieldsValue({
        cardColor: { hex: "#f44336" },
      });
    }
  }, [mode, edit, form]);

  useEffect(() => {
    if (loading) {
      dispatch({ type: "LOADING" });
    }
    if (data) {
      dispatch({ type: "DONE" });
    }

    if (error) {
      dispatch({ type: "ERROR" });
      setErrorMessage("Create Reward error");
      throw error;
    }

    if (updateRewardOjb.loading) {
      dispatch({ type: "LOADING" });
    }

    if (updateRewardOjb.error) {
      dispatch({ type: "ERROR" });
      setErrorMessage("Create Reward error");
      throw error;
    }
  }, [data, loading, error, updateRewardOjb.loading, updateRewardOjb.error]);

  const popoverContent = {
    activityName: {
      content: "ชื่อกิจกรรมเข่น วิ่งเล่นๆ 2565",
      position: "bottom",
    },
    targetPoint: {
      content: "คะแนนที่สะสมครบเพื่อรับรางวัล",
      position: "bottom",
    },
    startDate: {
      content: "วันที่เริ่มการสะสมคะแนน",
      position: "bottom",
    },
    endDate: {
      content:
        "วันที่สิ้นสุดการสะสมคะแนน หากไม่ได้กำหนดจะถือว่าสามารถสะสมได้ตลอด",
      position: "bottom",
    },
    method: {
      content: "นักวิ่งจะได้รับคะแนนจากการวิ่งในกิจกรรมที่กำหนด",
      position: "bottom",
    },
    roleActivity: {
      content:
        "กรุณาใส่รายละเอียดกิจกรรมเช่น กิจกรรมนี้สะสมคะแนนจากการวิ่งในPark Run เท่านั้น",
      position: "bottom",
    },
    cardColor: {
      content: "กรุณาเลือกสีการ์ดกิจกรรม ดูตัวอย่างได้จากทางด้านขวา",
      position: "bottom",
    },
    rewardItem: {
      content: "รางวัลที่นักวิ่งจะได้รับ เช่นเสื้อ",
      position: "bottom",
    },
    rewardCharacter: {
      content: "ขนาดของรางวัล เช่น size S ไหล่ 53.5 ซม. ยาว67.5ซม.",
      position: "bottom",
    },
    image: {
      content: "ตัวอย่างรางวัล",
      position: "bottom",
    },
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const initialState = {
    uploading: false,
    allError: false,
    done: false,
    loading: false,
    imgLoadingDone: false,
  };

  const stateFunction = (state, action) => {
    switch (action.type) {
      case "LOADING":
        return {
          ...state,
          allError: false,
          loading: true,
        };
      case "CLEAR":
        return {
          ...state,
          allError: false,
          loading: false,
          done: false,
        };
      case "UPLOADING":
        return { ...state, uploading: true, allError: false, done: false };
      case "DONE":
        return { ...state, uploading: false, allError: false, done: true };
      case "IMGDONE":
        return {
          ...state,
          uploading: false,
          allError: false,
          done: false,
          imgLoadingDone: true,
        };
      case "ERROR":
        return { ...state, uploading: false, allError: true, done: false };
      case "REMOVE":
        return { ...state, uploading: false, allError: false, done: false };
      default:
        return;
    }
  };

  const [state, dispatch] = useReducer(stateFunction, initialState);

  const handleChange = ({ fileList }) => {
    // console.log(fileList);

    if (fileList.length === 0) {
      dispatch({ type: "REMOVE" });
      return;
    }

    if (fileList[0].status === "uploading") {
      dispatch({ type: "UPLOADING" });
    }

    if (fileList[0].status === "error") {
      dispatch({ type: "ERROR" });
    }

    if (fileList[0].status === "done") {
      dispatch({ type: "IMGDONE" });
    }
    setFileList(fileList);
  };

  const handleUploadingImage = async ({
    onSuccess,
    onError,
    onProgress,
    file,
  }) => {
    // console.log(file);
    if (!file) {
      dispatch({ type: "UPLOADING" });
      setTimeout(() => {
        dispatch({ type: "ERROR" });
        onError("timeover");
        return;
      }, 10000);
    } else {
      const storages = firebase.storage();
      const metadata = {
        contentType: "image/jpeg",
      };
      const storageRef = await storages.ref();
      const imageName = `${activityName.split(" ").join("")}_${moment()}`; // a unique name for the image
      const imgFile = storageRef.child(`Reward/${imageName}.png`);
      try {
        await imgFile.put(file, metadata);

        const url = await imgFile.getDownloadURL();
        setImageURL(url);
      } catch (e) {
        onError(e);
      }
      onSuccess("done");
      dispatch({ type: "IMGDONE" });
    }
  };

  const handleRemove = () => {
    dispatch({ type: "REMOVE" });
    setFileList([]);
  };
  const handleCancel = () => setPreviewVisible(false);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChangeColor = (color, event) => {
    setCardColor(color.hex);
    setCircleColor(color.hex);
  };

  const handleSubmit = (value) => {
    //KEEP DATA AND SET SOME DATA
    const createDataInDB = async () => {
      const createData = {
        ...value,
        parkRun: value.method.includes("parkRun"),
        anyWhere: value.method.includes("anyWhere"),
        startDate: new Date(
          value.startDate
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toDate()
        ),
        cardColor: value.cardColor.hex,
        endDate:
          value.endDate === undefined
            ? null
            : new Date(
                value.endDate
                  .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                  .toDate()
              ),
        createdDate: new Date(Date.now()),
        modifiedDate: new Date(Date.now()),
        deleted: false,
        image: imageUrl ? imageUrl : defaultImage,
      };

      delete createData.method;

      try {
        const res = await createOneReward({
          variables: {
            record: createData,
          },
        });

        if (res) {
          form.resetFields();
          setActivityName(null);
          setPointTarget(null);
          setFileList([]);
          setImageURL(defaultImage);
          setCardColor("#f44336");
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    };

    const editDataInDB = async () => {
      const editData = {
        ...value,
        parkRun: value.method.includes("parkRun"),
        anyWhere: value.method.includes("anyWhere"),
        // startDate: value.startDate.toDate(),
        startDate: value.startDate
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toDate(),
        cardColor: value.cardColor.hex,
        endDate:
          value.endDate === undefined || value.endDate === null
            ? null
            : value.endDate
                .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                .toDate(),

        modifiedDate: new Date(Date.now()),
        _id: edit._id,
        image: imageUrl,
      };

      delete editData.method;

      await updateRewardFn({
        variables: {
          record: editData,
        },
      });
    };

    if (mode === "create") {
      createDataInDB();
      refetchData();
    }

    if (mode === "edit") {
      (async function () {
        await editDataInDB();
        await refetchData();
        handleCloseModal();
      })();
    }
  };

  const handleCloseModalAdded = () => {
    refetchData();

    dispatch({ type: "CLEAR" });
    // setTimeout(() => {
    //   dispatch({ type: "CLEAR" });
    // }, 2000);
  };

  return (
    <div className="reward-form">
      {state.loading && <Spin />}
      {state.allError && (
        <Modal
          visible={state.allError}
          title={"Error"}
          footer={null}
          onCancel={() => dispatch({ type: "CLEAR" })}
        >
          <p>{errorMessage}</p>
        </Modal>
      )}

      {state.done && (
        <Modal
          visible={state.done}
          title={"สำเร็จ"}
          footer={null}
          onCancel={handleCloseModalAdded}
        >
          <p>{"เพิ่มรางวัลเรียบร้อยแล้ว"}</p>
        </Modal>
      )}
      <Row gutter={0}>
        <Col xs={{ span: 24, order: 2 }} md={{ span: 14, order: 1 }}>
          <div className="reward-form-form">
            <Form
              form={form}
              name={mode}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
              onFinish={handleSubmit}
              autoComplete="off"
              initialValues={{
                rewardItem: "เสื้อ",
                sizeList: ["S", "M", "L", "XL", "XXL"],
              }}
            >
              <Popover
                content={<p>{popoverContent.activityName.content}</p>}
                placement={`${popoverContent.activityName.position}`}
              >
                <Form.Item
                  label="ชื่อกิจกรรม"
                  name="activityName"
                  rules={[{ required: true, message: "กรุณากำหนดชื่อกิจกรรม" }]}
                >
                  <Input
                    onChange={(e) => setActivityName(e.target.value)}
                    maxLength={16}
                    required
                  />
                </Form.Item>
              </Popover>
              <Popover
                content={<p>{popoverContent.targetPoint.content}</p>}
                placement={`${popoverContent.targetPoint.position}`}
                trigger="click"
              >
                <Form.Item
                  label="คะแนนสะสม"
                  name="pointTarget"
                  rules={[{ required: true, message: "กรุณากำหนดคะแนนสะสม" }]}
                >
                  <InputNumber
                    min={1}
                    // defaultValue={1}
                    onChange={(value) => setPointTarget(value)}
                    required
                  />
                </Form.Item>
              </Popover>

              <Form.Item
                label="วันที่เริ่มการสะสม"
                name="startDate"
                rules={[
                  { required: true, message: "กรุณากำหนดวันเริ่มการสะสม" },
                ]}
              >
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
              {/* </Popover> */}
              <Popover
                content={<p>{popoverContent.endDate.content}</p>}
                placement={`${popoverContent.endDate.position}`}
                trigger="hover"
              >
                <Form.Item label="วันที่สิ้นสุดการสะสม" name="endDate">
                  <DatePicker format={"DD-MM-YYYY"} />
                </Form.Item>
              </Popover>
              <Popover
                content={<p>{popoverContent.method.content}</p>}
                placement={`${popoverContent.method.position}`}
                trigger="click"
              >
                <Form.Item
                  name="method"
                  label="นับคะแนนจาก"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาเลือกกิจกรรมที่นำมานับเป็นคะแนน",
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Row>
                      <Col span={12}>
                        <Checkbox
                          value="parkRun"
                          style={{ lineHeight: "32px" }}
                        >
                          Park Run
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox
                          value="anyWhere"
                          style={{ lineHeight: "32px" }}
                        >
                          Anywhere
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Popover>
              <Popover
                content={<p>{popoverContent.roleActivity.content}</p>}
                placement={`${popoverContent.roleActivity.position}`}
                trigger="click"
              >
                <Form.Item label="กติกา" name="roleActivity">
                  <TextArea rows={4} />
                </Form.Item>
              </Popover>
              <Popover
                content={<p>{popoverContent.cardColor.content}</p>}
                placement={`${popoverContent.cardColor.position}`}
                trigger="click"
              >
                <Form.Item label="สีการ์ด" name="cardColor">
                  <CirclePicker
                    color={circleColor}
                    onChange={handleChangeColor}
                  />
                </Form.Item>
              </Popover>
              <Popover
                content={<p>{popoverContent.rewardItem.content}</p>}
                placement={`${popoverContent.rewardItem.position}`}
                trigger="click"
              >
                <Form.Item
                  label="รางวัล"
                  name="rewardItem"
                  rules={[{ required: true, message: "กรุณาเลือกขนาดเสื้อ" }]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Popover>
              <Form.Item name="sizeList" label="ขนาดเสื้อ">
                <Checkbox.Group>
                  <Row>
                    <Col span={8}>
                      <Checkbox value="2XS" style={{ lineHeight: "32px" }}>
                        2XS
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="XS" style={{ lineHeight: "32px" }}>
                        XS
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="S" style={{ lineHeight: "32px" }}>
                        S
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="M" style={{ lineHeight: "32px" }}>
                        M
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="L" style={{ lineHeight: "32px" }}>
                        L
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="XL" style={{ lineHeight: "32px" }}>
                        XL
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="2XL" style={{ lineHeight: "32px" }}>
                        2XL
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="3XL" style={{ lineHeight: "32px" }}>
                        3XL
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="4XL" style={{ lineHeight: "32px" }}>
                        4XL
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Popover
                content={<p>{popoverContent.rewardCharacter.content}</p>}
                placement={`${popoverContent.rewardCharacter.position}`}
                trigger="click"
              >
                <Form.Item
                  label="อธิบายขนาดเสื้อ"
                  name="rewardCharacter"
                  rules={[{ required: true, message: "กรุณาอธิบายขนาดเสื้อ" }]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Popover>
              <Popover
                content={<p>{popoverContent.image.content}</p>}
                placement={`${popoverContent.image.position}`}
                trigger="click"
              >
                <Form.Item label="รูปภาพรางวัล" name="image">
                  <Upload
                    customRequest={handleUploadingImage}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    onRemove={handleRemove}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Form.Item>
              </Popover>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                {mode === "create" && (
                  <Button type="primary" htmlType="submit">
                    เพิ่มกิจกรรม
                  </Button>
                )}
                {mode === "edit" && (
                  <Button type="primary" htmlType="submit">
                    แก้ไขกิจกรรม
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={{ span: 24, order: 1 }} md={{ span: 10, order: 2 }}>
          <div className="reward-form-card ">
            <RewardCardV2
              cardColor={cardColor}
              activityName={activityName}
              pointTarget={pointTarget}
              image={imageUrl}
            />
            {/* <RewardCard
              cardColor={cardColor}
              activityName={activityName}
              pointTarget={pointTarget}
              image={imageUrl}
            /> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}
