const firebaseConfig = {
  apiKey: "AIzaSyANTo6Y9YNxZTnRrXdmZtWBLgqAvLY9GFg",
  authDomain: "parkrun-th.firebaseapp.com",
  databaseURL: "https://parkrun-th.firebaseio.com",
  projectId: "parkrun-th",
  storageBucket: "parkrun-th.appspot.com",
  messagingSenderId: "473144066166",
  appId: "1:473144066166:web:20edcc7ad59d4c891cdf57",
  measurementId: "G-DWLE7ZRBM0"
};

export default firebaseConfig