import gql from "graphql-tag";

export default gql`
  mutation createReward($record: CreateOnerewardsInput!) {
    rewardCreateOne(record: $record) {
      record {
        pointTarget
        _id
      }
    }
  }
`;
