import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import reportList from "./reportList";
import allrunner from "./allRunner";
import allAnywhere from "./allAnywhere";
import Reward from "./Reward";

class Races extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/report" component={reportList} />
        <Route exact path="/report/parkrun" component={allrunner} />
        <Route exact path="/report/reward" component={Reward} />
        <Route exact path="/report/anywhere" component={allAnywhere} />
      </Switch>
    );
  }
}

export default Races;
