import React, { useState,Component } from 'react'
import { Layout, Table } from 'antd'
import moment from 'moment'
import _get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'
import getAnywhere from '../../graphql/queries/getAnywhere'

function calcTime(before, current){
  const curTime = moment(current)
  const beforeTime = moment(before)
  const diff = curTime.diff(beforeTime)
  const duration = moment.duration(diff)
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
}

const AnywhereList = () => {

	const { data, loading } = useQuery(getAnywhere, 
    { fetchPolicy: 'network-only' }
	)
  

  if (loading) return <div>loading...</div>

	const { checkpointMany } = data

	const columns = [
    {
      title: 'เลขบิบ',
      dataIndex: 'user',
      key: 'bib',
      render: user => <div>{`${user.bib}`}</div>
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'user',
      key: 'name',
      render: user => <div>{`${user.name}`}</div>
    },
    {
      title: 'เวลา',
      dataIndex: 'startTime',
      key: 'time',
      render: (startTime, record) => <div>{`${calcTime(record.startTime, record.endTime)} (${moment(startTime).format('LL , HH:mm:ss')})`}</div>
    },
		{
			title: 'ที่มา',
      dataIndex: 'apiClient',
      key: 'apiClient',
      render: apiClient => _get(apiClient, 'name') ? <div>{`${_get(apiClient, 'name')}`}</div>: ''
		}
  ]

	return (
		<Layout>
      <Layout.Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
        {/* <Row gutter={[16, 16]}>
          <Col xs={24} md={{ span: 3, offset: 1 }}>
            <Search
              placeholder="ค้นหา"
              onChange={ e => setSearchKey(e.target.value)}
              style={!isMobile ? { width: '388px', margin: '8px' } : null}
              />
          </Col>
        </Row> */}
          <Table
            // onRow={(record, rowIndex) => {
            //   // console.log({record})
            //   return {
            //     onClick: event => {
            //       history.replace(`/events/${record._id}/edit`)
            //     } // click row
            //   }
            // }}
            style={{whiteSpace: 'nowrap', background: '#fff'}}
            scroll={{ x: true }}
            columns={columns}
            dataSource={checkpointMany}
            loading={loading}
            locale={{emptyText: 'ไม่มีข้อมูล'}}
            rowKey={record => record._id}
            pagination={{
              defaultPageSize: 50
            }}
          />
      </Layout.Content>
    </Layout>
	)
}

export default AnywhereList