import gql from "graphql-tag";

export default gql`
  mutation userPointsCreateManyByImportXls($records: [records]) {
    userPointsCreateManyByImportXls(records: $records) {
      userId
      _id
      rewardDetail
      userName
      userId
      userBib
      points
      address
      achieved
      rewardStatus
      trackingNo
      achieveDate
    }
  }
`;
