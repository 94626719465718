import React from "react";
import { useState, useEffect } from "react";
import { Layout, Tabs, Spin, Modal } from "antd";
import RewardReport from "./Reward/RewardReport";
import RewardList from "./Reward/RewardList";
import RewardCreate from "./Reward/RewardCreate";
import rewardMany from "../../graphql/queries/rewardMany";
import { useQuery } from "@apollo/react-hooks";

export default function Reward() {
  const { TabPane } = Tabs;
  const { data, loading, error, refetch } = useQuery(rewardMany);

  const [allError, setAllError] = useState(false);
  const [allLoading, setAllLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rewardData, setRewardData] = useState(null);

  useEffect(() => {
    if (error) {
      console.log(error);
      setAllError(true);
      setErrorMessage("เกิดข้อผิดพลาด กรุณาลองอีกครั้ง");
      throw error;
    }

    if (loading) {
      setAllLoading(true);
    }

    if (data) {
      setAllLoading(false);
      setAllError(false);
      setErrorMessage("");
      // console.log(data);
      setRewardData(data.rewardMany);
    }
  }, [data, error, loading]);

  useEffect(() => {
    refetch();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const refetchData = () => {
    refetch();
  };

  return (
    <Layout>
      <Layout.Content
        style={{
          background: "#fff",
          padding: 24,
          margin: 0,
          minHeight: 280,
          position: "relative",
        }}
      >
        {allLoading && (
          <div
            style={{
              backgroundColor: "white",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
              textAlign: "center",
              padding: "20rem 0",
            }}
          >
            <Spin spinning={allLoading} size={"large"} />
          </div>
        )}
        <Modal visible={allError} onCancel={() => setAllError(false)}>
          <p>{errorMessage}</p>
        </Modal>
        <Tabs type="card">
          <TabPane tab="รายงานการรับรางวัล" key="1">
            <RewardReport rewardData={rewardData} refetchData={refetchData} />
          </TabPane>
          <TabPane tab="กิจกรรมทั้งหมด" key="2">
            <RewardList rewardData={rewardData} refetchData={refetchData} />
          </TabPane>
          <TabPane tab="เพิ่มกิจกรรม" key="3">
            <RewardCreate refetchData={refetchData} />
          </TabPane>
        </Tabs>
      </Layout.Content>
    </Layout>
  );
}
