import React from "react";
import RewardForm from "./RewardForm";

export default function RewardCreate({ refetchData }) {
  // const [mode, setMode] = useState("create");

  return (
    <div>
      <RewardForm mode={"create"} refetchData={refetchData} />
    </div>
  );
}
