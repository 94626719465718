import gql from "graphql-tag";

export default gql`
  query {
    rewardMany(filter: { deleted: false }) {
      activityName
      startDate
      endDate
      #method
      parkRun
      anyWhere
      roleActivity
      cardColor
      rewardItem
      sizeList
      rewardCharacter
      image
      pointTarget
      _id
    }
  }
`;
