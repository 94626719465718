import React, { useEffect, useState } from "react";
import { Table, Space, Image, Modal, Button, Alert, Popconfirm } from "antd";
// import RewardCard from "./RewardCard";
import RewardCardV2 from "./RewardCard_V2";

import moment from "moment";
import RewardForm from "./RewardForm";
import { useMutation } from "@apollo/react-hooks";
import updateRewardById from "../../../graphql/mutations/updateRewardById";

export default function RewardList({ rewardData, refetchData, loading }) {
  const [data, setData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [edit, setEdit] = useState(null);
  // const [mode, setMode] = useState("edit");
  const [updateRewardFn, updateRewardOjb] = useMutation(updateRewardById);

  useEffect(() => {
    refetchData();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let dataset = [];

    rewardData.forEach((el, i) => {
      let stringSize = "";
      el.sizeList.forEach((size) => {
        stringSize = stringSize + size + ",";
      });

      dataset.push({
        ...el,
        method: `${el.parkRun ? "Park Run" : ""} ${
          el.anyWhere ? "Anywhere" : ""
        }`,
        key: i,
        sizeList: stringSize,
        startDate: moment(el.startDate).format("DD-MM-YYYY"),
        endDate: el.endDate
          ? moment(el.endDate).format("DD-MM-YYYY")
          : "ไม่กำหนด",
      });
    });

    setData(dataset);
  }, [rewardData]);

  const columns = [
    {
      title: "ชื่อกิจกรรม",
      dataIndex: "activityName",
      key: "activityName",
    },
    {
      title: "คะแนนสะสม",
      dataIndex: "pointTarget",
      key: "pointTarget",
    },
    { title: "วันที่เริ่มมการสะสม", dataIndex: "startDate", key: "startDate" },
    { title: "วันที่สิ้นสุดการสะสม", dataIndex: "endDate", key: "endDate" },
    { title: "นับคะแนนจาก", dataIndex: "method", key: "method" },
    { title: "รางวัล", dataIndex: "rewardItem", key: "rewardItem" },
    { title: "ขนาด", dataIndex: "sizeList", key: "sizeList" },

    {
      title: "Action",
      key: "operation",
      render: (value) => (
        <Space>
          <Button type="link" onClick={(e) => handleEdit(e, value)}>
            แก้ไข
          </Button>
          <Popconfirm
            title="คุณต้องการลบกิจกรรมนี้ ? "
            onConfirm={(e) => handleDelete(e, value)}
            // onCancel={cancel}
            okText="ใช่"
            cancelText="ไม่"
          >
            <Button type="link">ลบ</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const expandedRowRender = (rec) => {
    const columns = [
      {
        title: "กติกา",
        dataIndex: "roleActivity",
        key: "roleActivity",
        // ellipsis: true,
      },

      {
        title: "อธิบายรางวัล",
        dataIndex: "rewardCharacter",
        key: "rewardCharacter",
        // ellipsis: true,
      },

      {
        title: "รูปภาพ",
        dataIndex: "image",
        key: "image",
        render: () => <Image src={rec.image} style={{ width: 200 }} />,
      },
      {
        title: "สีการ์ด",
        dataIndex: "cardColor",
        key: "cardColor",
        render: () => (
          <RewardCardV2
            cardColor={rec.cardColor}
            activityName={rec.activityName}
            pointTarget={rec.pointTarget}
            image={rec.image}
          />
          // <RewardCard
          //   cardColor={rec.cardColor}
          //   activityName={rec.activityName}
          //   pointTarget={rec.pointTarget}
          //   image={rec.image}
          // />
        ),
      },
    ];

    const dataExpand = [rec];

    return (
      <Table columns={columns} dataSource={dataExpand} pagination={false} />
    );
  };

  const handleEdit = (e, value) => {
    e.preventDefault();
    // console.log(value);
    const rewardValue = rewardData.find((el) => el._id === value._id);
    setEdit(rewardValue);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setEdit(null);
    setIsModalVisible(false);
    refetchData();
  };

  const handleDelete = (e, value) => {
    e.preventDefault();

    // console.log(value);
    updateRewardFn({
      variables: {
        record: {
          _id: value._id,
          deleted: true,
          modifiedDate: new Date(Date.now()),
        },
      },
    });

    //REFETCHDATA
    refetchData();
  };

  return (
    <div className="reward-list" style={{ position: "relative" }}>
      {updateRewardOjb.loading && (
        <Alert
          message="แก้ไขข้อมูล"
          description="กำลังแก้ไขข้อมุลกิจกรรม"
          type="info"
          showIcon
        />
      )}

      {updateRewardOjb.error && (
        <Alert
          message="เกิดความผิดพลาด"
          description="ไม่สามารถลบข้อมูลได้"
          type="error"
          showIcon
        />
      )}

      <Modal
        title="แก้ไขข้อมูลการรับรางวัล"
        visible={isModalVisible}
        getContainer={false}
        onCancel={handleCancel}
        width={1200}
        footer={[
          <Button key="back" onClick={handleCancel}>
            ยกเลิก
          </Button>,
        ]}
      >
        {edit && (
          <RewardForm
            mode={"edit"}
            edit={edit}
            handleCloseModal={handleCancel}
            refetchData={refetchData}
          />
        )}
      </Modal>

      <Table
        className="components-table-demo-nested"
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={data}
      />
    </div>
  );
}
