import gql from 'graphql-tag'

export default gql`
query{
    checkpointMany(filter:{
        deleted: false,
        isVirtual: true
      }) {
        _id
        runnerId
        user{
            name
            bib
        }
        startTime
        endTime
        location
        apiClientId
        apiClient{
            name
        }
    }
  }
`