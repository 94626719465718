import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Select,
  Space,
  Button,
  Tag,
  Modal,
  Upload,
  Alert,
  Input,
} from "antd";

import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";
import RewardEditAchieveForm from "./RewardEditAchieveForm";
import moment from "moment";
import ReactExport from "react-data-export";
// import userPointsReport from "./../../../graphql/queries/userPointsReport";
import userPointsMany from "./../../../graphql/queries/userPointsMany";
import rewardOne from "./../../../graphql/queries/rewardOne";
import userPointsCreateManyByImportXls from "./../../../graphql/mutations/userPointsCreateManyByImportXls";
import papa from "papaparse";
import * as XLSX from "xlsx";

export default function RewardReport({ rewardData }) {
  const { Option } = Select;
  const [error, setError] = useState(null);
  const [searchPerson, setSearchPerson] = useState([]);
  const [eventSelected, setEventSelected] = useState(null);
  const [edit, setEdit] = useState(null);
  const [rewardName, setRewardName] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalImportXlxRejectVisible, setisModalImportXlxRejectVisible] =
    useState(false);
  const [importCompleted, setImportCompleted] = useState(null);
  const [rowSelected, setRowSelected] = useState([]);
  const [reward, setReward] = useState(null);
  const { Search } = Input;
  // const [userPointFn, userPointObj] = useLazyQuery(userPointsReport);
  const [userPointFn, userPointObj] = useLazyQuery(userPointsMany);

  const dataForTable = useRef([]);
  const dataRejectedByImportXlxs = useRef([]);

  const rewardOneObj = useQuery(rewardOne, {
    variables: { filter: { _id: eventSelected } },
  });

  const [userPointsCreateByXlsFn, userPointsCreateByXlsObj] = useMutation(
    userPointsCreateManyByImportXls
  );
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  useEffect(() => {
    if (!eventSelected) {
      return;
    }

    userPointFn({
      variables: {
        filter: {
          rewardId: eventSelected,
          achieved: true,
        },
        limit: 100000,
      },
    });

    // userPointFn({
    //   variables: {
    //     rewardId: eventSelected,
    //   },
    // });

    if (rewardOneObj.data) {
      setReward(rewardOneObj.data.rewardOne);
    }
  }, [eventSelected, userPointFn, rewardOneObj]);

  if (userPointObj.error || rewardOneObj.error)
    setError("เกิดข้อผิดพลาดในการติดต่อฐานข้อมูล");
  if (userPointsCreateByXlsObj.error)
    setError("เกิดข้อผิดพลาดในการอัพโหลดข้อมูล");

  const dataTable = (datas) => {
    const dataIntable = [];
    if (!datas) return;
    if (datas.length === 0) return;

    datas.forEach((el, i) => {
      if (!el.deleted) {
        dataIntable.push({
          key: i,
          bib: el.user.bib,
          name: el.user.name,
          phone: el.user.phone,
          address: el.address ? el.address : "-",
          achieveDate: `${moment(el.achieveDate).format("DD-MM-YYYY")}`,
          size: el.rewardDetail ? el.rewardDetail : "-",
          trackingNo: el.trackingNo ? el.trackingNo : "-",
          status: el.rewardStatus,
          action: el,
          sort:
            el.status === "สำเร็จ" ? 1 : el.status === "เตรียมรางวัล" ? 0 : -1,
        });
      }
    });

    dataIntable.sort((a, b) =>
      a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0
    );

    return dataIntable;
  };

  if (userPointObj.data) {
    dataForTable.current = dataTable(userPointObj.data.userPointsMany);
  }

  if (searchPerson.length > 0) {
    dataForTable.current = dataTable(searchPerson);
  }

  const handleEdit = (value) => {
    setEdit({ ...value });
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "bib",
      dataIndex: "bib",
      sorter: (a, b) => a.bib - b.bib,
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "name",
    },
    {
      title: "ที่อยู่",
      dataIndex: "address",
      ellipsis: true,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "phone",
    },

    {
      title: "วันที่วิ่งครบ",
      dataIndex: "achieveDate",
      sorter: (a, b) =>
        moment(a.achieveDate, "DD-MM-YYYY") -
        moment(b.achieveDate, "DD-MM-YYYY"),
    },
    {
      title: "ขนาด",
      dataIndex: "size",
    },
    {
      title: "เลขที่พัสดุ",
      dataIndex: "trackingNo",
    },
    {
      title: "สถานะการรับรางวัล",
      dataIndex: "status",
      filters: [
        { text: "ยืนยันข้อมูล", value: "ยืนยันข้อมูล" },
        { text: "เตรียมรางวัล", value: "เตรียมรางวัล" },
        { text: "สำเร็จ", value: "สำเร็จ" },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (value) => (
        <Space size="middle">
          <Tag
            color={
              value === "สำเร็จ"
                ? "green"
                : value === "เตรียมรางวัล"
                ? "orange"
                : "red"
            }
          >
            {value}
          </Tag>
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (value) => (
        <Space>
          <Button type="link" onClick={() => handleEdit(value)}>
            แก้ไข
          </Button>
        </Space>
      ),
    },
  ];

  const expandable = {
    expandedRowRender: (record) => <p>{record.address}</p>,
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleCancel = () => {
    userPointObj.refetch();

    setEdit(null);
    setIsModalVisible(false);
  };

  const handleEventSelect = (value) => {
    setEventSelected(value);
    const activityName = rewardData.find((el) => el._id === value).activityName;
    setRewardName(activityName);
  };

  const handleImportDataFormFile = (file) => {
    //THIS IS FUNCTION IS NOT KEEP THE FILE , JUST READ IT
    const reader = new FileReader();

    reader.onload = async (e) => {
      const { result } = e.target;
      const wb = XLSX.read(result, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const dataXls = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      let { data: datas } = papa.parse(dataXls, { header: true });
      let records = [];
      let rejectRecords = [];

      const varidateNoneString = (str) => {
        return str.toUpperCase().replace(/[.*+?^${}()|[\]\\-]/, "");
      };

      if (datas.length === 0) {
        setError("ไม่พบข้อมูลในไฟล์ Excel");
        return;
      }

      ///CHECK DATA HEADER
      datas.forEach((data) => {
        if (
          !data.hasOwnProperty("name") &&
          !data.hasOwnProperty("bib") &&
          !data.hasOwnProperty("size") &&
          !data.hasOwnProperty("timeCount") &&
          !data.hasOwnProperty("address") &&
          !data.hasOwnProperty("trackingNo") &&
          !data.hasOwnProperty("achieveDate")
        ) {
          setError("หัวข้อของตารางไม่ถูกต้อง");
          return;
        }

        //CHECK BIB
        if (data.bib === "" || !data.bib) {
          rejectRecords.push({ ...data, remark: "missing bib no." });
          return;
        }

        //QUERY THE REWARD THEN CHECK POINT TARGET
        if (+data.timeCount < reward.pointTarget) {
          rejectRecords.push({
            ...data,
            remark: "timeCount is less than reward point target",
          });
          // console.log(data);
          return;
        }

        /// CHECK VALID OF DATA.ACHIEVEDATE
        if (reward.endDate) {
          const achieveDateSetTime = moment(data.achieveDate)
            .set({
              hour: 0,
              minute: 0,
              second: 1,
              millisecond: 0,
            })
            .toDate();

          if (
            achieveDateSetTime > new Date(reward.endDate) ||
            achieveDateSetTime < new Date(reward.startDate)
          ) {
            console.log(data);
            rejectRecords.push({
              ...data,
              remark: "achieveDate is out of reward active date",
            });
            return;
          }
        }

        records.push({
          rewardId: eventSelected,
          rewardDetail: varidateNoneString(data.size),
          userName: data.name,
          userBib: +data.bib,
          points: reward.pointTarget,
          address: data.address,
          achieved: +data.timeCount >= reward.pointTarget ? true : false,
          rewardStatus:
            data.size && data.address
              ? data.trackingNo
                ? "สำเร็จ"
                : "เตรียมรางวัล"
              : "ยืนยันข้อมูล",
          trackingNo: data.trackingNo,
          achieveDate: new Date(data.achieveDate),
        });
      });

      ///SEND RECORD TO BACKEND
      if (records.length === 0) {
        setError(
          "ไม่มีผู้ได้รับรางวัล : โปรดตรวจสอบคะแนนของนักวิ่งและคะแนนของรางวัล  หรือ วันที่วิ่งครบและวันที่เริ่ม-สิ้นสุดการสะสมคะแนน"
        );
        return;
      }

      //!!!! THIS MUTATION WILL RETURN THE REJECT ARRAY OF RECORDS ,WHICH IS NOT FOUND USER BY FINDING BIB NO.
      const rejected = await userPointsCreateByXlsFn({
        variables: { records },
      });

      if (userPointsCreateByXlsObj.data) {
        setImportCompleted("นำเข้าข้อมูลสำเร็จ");

        setTimeout(() => {
          setImportCompleted(null);
        }, 3000);
      }

      if (
        rejectRecords.length > 0 ||
        rejected.data.userPointsCreateManyByImportXls.length > 0
      ) {
        handleShowModalRejectRecordXls(rejectRecords, rejected);
      }
      userPointObj.refetch();
    };
    reader.readAsBinaryString(file);
  };

  const handleShowModalRejectRecordXls = (rejectRecords, rejected) => {
    let rejectedDatas = [];

    if (rejectRecords) {
      rejectedDatas = rejectedDatas.concat(rejectRecords);
    }

    if (rejected) {
      const rej = rejected.data.userPointsCreateManyByImportXls.map((el) => {
        return {
          achieveDate: el.achieveDate,
          address: el.address,
          bib: el.userBib,
          size: el.rewardDetail,
          timeCount: el.points,
          trackingNo: el.trackingNo,
          remark: "Bib no. is not existed",
        };
      });

      rejectedDatas = rejectedDatas.concat(rej);
    }
    dataRejectedByImportXlxs.current = rejectedDatas;
    setisModalImportXlxRejectVisible(true);
  };

  const handleClearError = (e) => {
    setError(null);
  };

  const handleSearch = (value) => {
    const searchUser = userPointObj.data.userPointsMany.filter((el) => {
      return el.user.name
        ? el.user.name.toLowerCase().includes(value.toLowerCase())
        : null;
    });
    setSearchPerson(searchUser);
  };

  const handleSearchBybib = (value) => {
    const searchBib = userPointObj.data.userPointsMany.filter(
      (el) => el.user.bib === +value
    );
    setSearchPerson(searchBib);
  };

  return (
    <div>
      {
        <Modal
          title="มีข้อมูลที่ไม่สามารถนำเข้า"
          visible={isModalImportXlxRejectVisible}
          getContainer={false}
          onCancel={handleCancel}
          width={300}
          footer={[
            <Button
              key="back"
              onClick={() => setisModalImportXlxRejectVisible(false)}
            >
              ยกเลิก
            </Button>,
          ]}
        >
          <ExcelFile
            element={<Button>ดาวน์โหลดข้อมูลที่ไม่สามารถนำเข้า</Button>}
            filename={`RejectedData_${moment()
              .locale("th")
              .format("DD-MM-YYYY")}`}
          >
            <ExcelSheet
              data={dataRejectedByImportXlxs.current}
              name={`Rejected`}
            >
              <ExcelColumn label="bib" value="bib" />
              <ExcelColumn label="name" value="name" />
              <ExcelColumn label="phone" value="phone" />
              <ExcelColumn label="address" value="address" />
              <ExcelColumn label="email" value="email" />
              <ExcelColumn label="gender" value="gender" />
              <ExcelColumn label="timeCount" value="timeCount" />
              <ExcelColumn label="achieveDate" value="achieveDate" />
              <ExcelColumn label="size" value="size" />
              <ExcelColumn label="trackingNo" value="trackingNo" />
              <ExcelColumn label="remark" value="remark" />
            </ExcelSheet>
          </ExcelFile>
        </Modal>
      }
      {error && (
        <Alert
          message="เกิดข้อผิดพลาด"
          description={error}
          type="error"
          closable
          showIcon
          onClose={handleClearError}
        />
      )}
      {userPointsCreateByXlsObj.loading && (
        <Alert message="Importing" type="info" showIcon />
      )}

      {importCompleted && (
        <Alert message={importCompleted} type="success" showIcon />
      )}
      <Modal
        title="แก้ไขข้อมูลการรับรางวัล"
        visible={isModalVisible}
        getContainer={false}
        onCancel={handleCancel}
        width={1200}
        footer={[
          <Button key="back" onClick={handleCancel}>
            ยกเลิก
          </Button>,
        ]}
      >
        {edit && (
          <RewardEditAchieveForm
            achievement={edit}
            handleCancel={handleCancel}
          />
        )}
      </Modal>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Space align="start">
          <p>กิจกรรม</p>
          <Select
            placeholder={"กรุณาเลือกประเภทรางวัล"}
            onChange={(value) => handleEventSelect(value)}
            style={{
              width: 250,
            }}
          >
            {rewardData &&
              rewardData.map((el, i) => (
                <Option key={i} value={el._id}>
                  {el.activityName}
                </Option>
              ))}
          </Select>
          <ExcelFile
            element={
              <Button disabled={rowSelected.length <= 0}>
                พิมพ์ที่อยู่ที่เลือก
              </Button>
            }
            filename={`Selected_${rewardName}_${moment()
              .locale("th")
              .format("DD-MM-YYYY")}`}
          >
            <ExcelSheet data={rowSelected} name={`${rewardName}`}>
              <ExcelColumn label="key" value="key" />
              <ExcelColumn label="name" value="name" />
              <ExcelColumn label="phone" value="phone" />
              <ExcelColumn label="address" value="address" />
              <ExcelColumn label="achieve date" value="achieveDate" />
              <ExcelColumn label="size" value="size" />
              <ExcelColumn label="trackingNo" value="trackingNo" />
              <ExcelColumn label="status" value="status" />
            </ExcelSheet>
          </ExcelFile>

          <ExcelFile
            element={
              <Button disabled={!eventSelected} icon={<DownloadOutlined />}>
                ดาวน์โหลดรายงานรางวัล
              </Button>
            }
            filename={`Reward_${rewardName}_${moment()
              .locale("th")
              .format("DD-MM-YYYY")}`}
          >
            <ExcelSheet data={dataForTable.current} name={`${rewardName}`}>
              <ExcelColumn label="key" value="key" />
              <ExcelColumn label="name" value="name" />
              <ExcelColumn label="phone" value="phone" />
              <ExcelColumn label="address" value="address" />
              <ExcelColumn label="achieve date" value="achieveDate" />
              <ExcelColumn label="size" value="size" />
              <ExcelColumn label="trackingNo" value="trackingNo" />
              <ExcelColumn label="status" value="status" />
            </ExcelSheet>
          </ExcelFile>

          <Upload
            showUploadList={false}
            beforeUpload={(file) => handleImportDataFormFile(file)}
          >
            <Button icon={<UploadOutlined />} disabled={!eventSelected}>
              นำเข้าข้อมูลด้วยไฟล์ Excel
            </Button>
          </Upload>
        </Space>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Search
            enterButton
            type={"number"}
            disabled={!eventSelected}
            placeholder="ค้นหาจากเลขที่bib"
            onSearch={handleSearchBybib}
            style={{ width: 200 }}
          />
          <Search
            enterButton
            disabled={!eventSelected}
            placeholder="ค้นหาจากชื่อนักวิ่ง"
            onSearch={handleSearch}
            style={{ width: 300 }}
          />
        </div>
      </div>

      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        expandable={expandable}
        pagination={{ position: "bottom" }}
        columns={columns}
        dataSource={dataForTable.current}
        style={{ position: "relative" }}
        loading={userPointObj.called && userPointObj.loading}
      />
    </div>
  );
}
