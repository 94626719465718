import gql from "graphql-tag";

export default gql`
  mutation userPointsUpdateById($record: UpdateByIduserpointsInput!) {
    userPointsUpdateById(record: $record) {
      record {
        _id
        rewardStatus
        rewardDetail
        address
      }
    }
  }
`;
