import gql from "graphql-tag";

export default gql`
  query ($userName: String) {
    userSearch(userName: $userName) {
      _id
      name
      role
      email
      phone
    }
  }
`;
