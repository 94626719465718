import gql from "graphql-tag";

export default gql`
  query ($filter: FilterFindManyuserpointsInput, $limit: Int) {
    userPointsMany(filter: $filter, limit: $limit) {
      _id
      deleted
      achieveDate
      address
      rewardStatus
      trackingNo
      userId
      user {
        bib
        name
        _id
        phone
      }
      rewardId
      rewardDetail
      reward {
        sizeList
      }
    }
  }
`;
