import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import _includes from 'lodash/includes'
import compose from 'lodash/flowRight'
import { useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'
// import './event.css';

import { Menu, Layout } from 'antd'
import { ExportOutlined } from '@ant-design/icons'

const { Sider } = Layout


const ReportSider = (props) => {
  const { match } = props



  const pathname = match.path.split('/')
  // else if (!(admin && event)) {
  //   return 'admin or event not found'
  // }

  return (
    <Sider
      width={200}
      style={{ background: '#fff' }}
      breakpoint="lg"
      collapsedWidth="0"
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={['/']}
        defaultOpenKeys={['sub0']}
        selectedKeys={[pathname[2]]}
        style={{ height: '100%' }}
      >
        <Menu.Item key="parkrun">
          <NavLink to={`/report/parkrun`}>
            <ExportOutlined style={{fontSize: 16, color: '#314659'}} />
              parkrun
          </NavLink>
        </Menu.Item>
        <Menu.Item key="anywhere">
          <NavLink to={`/report/anywhere`}>
            <ExportOutlined style={{fontSize: 16, color: '#314659'}} />
            parkrun @nywhere
          </NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

export default compose(
  withRouter
)(ReportSider)
